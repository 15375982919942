.already-logged-in-container{
    width: 35vw !important;
    border-top: 8px solid #4A154B;
    border-radius: 5px;
    padding: 10px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.continue-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4A154B;
    padding: 15px 10px;
    border-radius: 30px;
    width: 100%;
    margin-bottom: 1.9rem;
    font-weight: bold;
    cursor: pointer;
}
.continue-btn-container p{
margin-bottom: 0px !important;
font-size: 1.2rem;
color: white;
}


.top-section-container{
margin-top: 1.5rem;
margin-bottom: 1.5rem;
}

.top-section-container p{
font-size: 1.1rem;
}
.top-section-container p span{
font-weight: bold;
color: blue;
cursor: pointer;
}
.top-section-container h2{
font-weight: bolder;
}
.already-signed-in-clarification{
    margin-top: 2rem;
    margin-bottom: 2rem;
}



/* ------------------------------- 📲  CSS FOR NORMAL MOBILE PHONES 📲 ------------------------------------ */

@media (max-width: 480px) {
   .already-logged-in-container{
    width: 90vw !important;
    padding: 10px 15px;
} 
.continue-btn-container{
    background-color: #4A154B;
    padding: 13px 10px;
}
}